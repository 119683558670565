import { defineStore } from 'pinia'
import { NUMBER_OF_LEVELS } from '../enums'

const LEVEL_CHAPTERS = NUMBER_OF_LEVELS
const LEVEL_MULTIPLIER =
  Math.round((1 / LEVEL_CHAPTERS + Number.EPSILON) * 100) / 100
const initialGames = [
  {
    id: 'island-1',
    category: 'Math',
    type: 'Rocks Island +', // 'Addition [0-5]',
    range: '1 - 5',
    url: '/games/math/island-1',
    icon: 'i-heroicons-plus-circle',
    bg: '/img/WLearn_Island_1.webp',
    disabled: false,
    year: 1,
    chapter: 1,
    level: 1,
    progress: 0.1,
  },
  {
    id: 'island-2',
    category: 'Math',
    type: 'Flower Island +', // 'Addition [0-9]',
    range: '0 - 9',
    url: '/games/math/island-2',
    icon: 'i-heroicons-minus-circle',
    bg: '/img/WLearn_Island_2.webp',
    disabled: false,
    year: 1,
    chapter: 2,
    level: 1,
    progress: 0,
  },
  {
    id: 'island-3',
    category: 'Math',
    type: 'Mushroom Island', // 'Missing number [0-9]',
    range: '0 - 9',
    url: '/games/math/island-3',
    icon: 'i-heroicons-squares-2x2',
    bg: '/img/WLearn_Island_3.webp',
    disabled: false,
    year: 1,
    chapter: 3,
    level: 1,
    progress: 0,
  },
  {
    id: 'island-4',
    category: 'Math',
    type: 'Cone Island -', // 'Subtraction [0-9]',
    range: '0 - 9',
    url: '/games/math/island-4',
    icon: 'i-heroicons-chart-pie',
    bg: '/img/WLearn_Island_4.webp',
    disabled: false,
    year: 1,
    chapter: 4,
    level: 1,
    progress: 0,
  },
  {
    id: 'island-5',
    category: 'Math',
    type: 'The Green Island ', // 'Find the operator [0-9]',
    range: '0 - 9',
    url: '/games/math/island-5',
    icon: 'i-heroicons-chart-pie',
    bg: '/img/WLearn_Island_5.webp',
    disabled: false,
    year: 1,
    chapter: 5,
    level: 1,
    progress: 0,
  },
  {
    id: 'island-6',
    category: 'Math',
    type: 'Waterfall Island +', // 'Addition [11-20]',
    range: '1 - 20',
    url: '/games/math/island-6',
    icon: 'i-heroicons-chart-pie',
    bg: '/img/WLearn_Island_6.webp',
    disabled: false,
    year: 1,
    chapter: 6,
    level: 1,
    progress: 0,
  },
  {
    id: 'island-7',
    category: 'Math',
    type: 'Golden Island +', // 'Addition of tens [10-90]',
    range: '10 - 90',
    url: '/games/math/island-7',
    icon: 'i-heroicons-chart-pie',
    bg: '/img/WLearn_Island_7.webp',
    disabled: false,
    year: 1,
    chapter: 7,
    level: 1,
    progress: 0,
  },
  {
    id: 'island-8',
    category: 'Math',
    type: 'Trees Island', // 'Subtraction of tens [20-90]',
    range: '10 - 90',
    url: '/games/math/island-8',
    icon: 'i-heroicons-chart-pie',
    bg: '/img/WLearn_Island_8.webp',
    disabled: false,
    year: 1,
    chapter: 8,
    level: 1,
    progress: 0,
  },
  {
    id: 'island-9',
    category: 'Math',
    type: 'Beach Island', // 'Addition/Subtraction of double digits [20-99]',
    range: '20 - 99',
    url: '/games/math/island-9',
    icon: 'i-heroicons-chart-pie',
    bg: '/img/WLearn_Island_9.webp',
    disabled: false,
    year: 1,
    chapter: 9,
    level: 1,
    progress: 0,
  },
  {
    id: 'island-10',
    category: 'Math',
    type: 'Floating Island', // 'Missing number of double digits [20-99]',
    range: '20 - 99',
    url: '/games/math/island-10',
    icon: 'i-heroicons-chart-pie',
    bg: '/img/WLearn_Island_10.webp',
    disabled: false,
    year: 1,
    chapter: 10,
    level: 1,
    progress: 0,
  },
] as Game[]

export const useMathGamesStore = defineStore('mathGamesStore', () => {
  const games = ref(initialGames)
  const currentGame = ref({} as Game)

  function setCurrentGame(gameId: string) {
    currentGame.value =
      games.value.find((game) => game.id === gameId) || ({} as Game)
  }

  function dropCurrentGameLevel() {
    currentGame.value.level = Math.max(currentGame.value.level - 1, 1)
  }

  function updateGameProgress(isCorrect: boolean) {
    if (!Object.keys(currentGame.value).length) {
      return
    }
    if (isCorrect) {
      currentGame.value.progress = useRoundTwoDecimals(
        currentGame.value.progress + LEVEL_MULTIPLIER,
      )
      if (currentGame.value.progress >= 1) {
        currentGame.value.level = currentGame.value.level + 1
        currentGame.value.progress = 0
        if (currentGame.value.level === NUMBER_OF_LEVELS) {
          updateUnlockedIslands()
        }
      }
    } else {
      currentGame.value.progress = Math.max(
        currentGame.value?.progress - LEVEL_MULTIPLIER,
        0,
      )
    }
  }

  function updateUnlockedIslands() {
    games.value.forEach((game, index) => {
      if (game.level >= NUMBER_OF_LEVELS) {
        const nextGame = games.value[index + 1]
        nextGame.disabled = false
      }
    })
  }

  function resetGames() {
    games.value = initialGames
  }

  function setMathGameHistory(
    gameId: string,
    chapter: number,
    level: number,
    progress: number,
  ) {
    games.value.forEach((game) => {
      if (game.id === gameId) {
        if (chapter) {
          game.chapter = chapter
        }
        if (level) {
          game.level = level
        }
        if (progress) {
          game.progress = progress
        }
        game.disabled = false
      }
    })
    updateUnlockedIslands()
  }

  return {
    games,
    currentGame,
    setCurrentGame,
    dropCurrentGameLevel,
    updateGameProgress,
    setMathGameHistory,
    resetGames,
    updateUnlockedIslands,
  }
})
